import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createNewMeetingService, getAllCategoriesService, getAllMeetingService, getQuestionsService } from "../services/services";

export const getAllCategories = createAsyncThunk("meeting/getAllCategories", async (_, { rejectWithValue }) => {
  try {
    const response = await getAllCategoriesService();
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const getQuestions = createAsyncThunk("meeting/getQuestions", async (meetingData, { rejectWithValue }) => {
  try {
    const response = await getQuestionsService(meetingData);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const createNewMeeting = createAsyncThunk("meeting/createNewMeeting", async (meetingData, { rejectWithValue }) => {
  try {
    const response = await createNewMeetingService(meetingData);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const getAllMeetings = createAsyncThunk("meeting/getAllmeetings", async (_, { rejectWithValue }) => {
  try {
    const response = await getAllMeetingService();
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

const meetingSlice = createSlice({
  name: "meeting",
  initialState: {
    isLoading: false,
    searchForParticepent: "",
    categories: [],
    error: null,
    questions: [],
    createNewMeeting: null,
    allSavedMeetings: [],
  },
  reducers: {
    setMeetingName: (state, action) => {
      state.name = action.payload;
    },
    setMeetingDate: (state, action) => {
      state.date = action.payload;
    },
    resetCreateMeeting: (state, action) => {
      state.createNewMeeting = null;
      state.questions = [];
    },
    resetQuestions: (state, action) => {
      state.questions = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllCategories.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getAllCategories.fulfilled, (state, action) => {
        state.isLoading = false;
        state.categories = action.payload.data;
      })
      .addCase(getAllCategories.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(getQuestions.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getQuestions.fulfilled, (state, action) => {
        state.isLoading = false;
        // Assuming you want to update some state property with the questions
        // Adjust this line according to your state structure
        state.questions = action.payload.data;
      })
      .addCase(getQuestions.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(createNewMeeting.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createNewMeeting.fulfilled, (state, action) => {
        state.isLoading = false;
        state.createNewMeeting = action.payload;
      })
      .addCase(createNewMeeting.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(getAllMeetings.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getAllMeetings.fulfilled, (state, action) => {
        state.isLoading = false;
        state.allSavedMeetings = action.payload.data;
      })
      .addCase(getAllMeetings.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { setMeetingName, setMeetingDate, resetCreateMeeting, resetQuestions } = meetingSlice.actions;

export default meetingSlice.reducer;
