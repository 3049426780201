// import React, { useEffect, useState } from "react";
// import { MotionAnimate } from "react-motion-animate";
// import Loader from "../components/Loader";
// import { MDBBtn, MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
// import { Typewriter } from "react-simple-typewriter";
// import { MDBDataTable } from "mdbreact";
// import { Edit, Download, DeleteForever } from "@mui/icons-material";
// import Breadcrumb from "../components/common/BreadCrumb";
// import { breadcrumbItems } from "../constants/breadcrumb";
// import { useDispatch, useSelector } from "react-redux";
// import { getAllMeetings } from "../redux/slices/meetingSlice";

// const SavedMeetings = () => {
//   const dispatch = useDispatch();
//   const savedMeetings = useSelector((state) => state.meeting.allSavedMeetings);

//   console.log({ savedMeetings });

//   const [tdata, settdata] = useState([
//     {
//       select: false, // Checkbox initially unchecked
//       Sr: 1,
//       MeetingName: "Project Kickoff Meeting",
//       CompanyName: "ABC Tech",
//       Participant1: "John Doe",
//       Participant2: "Jane Smith",
//       Participant3: "David Lee",
//     },
//     {
//       select: false, // Checkbox initially unchecked
//       Sr: 2,
//       MeetingName: "Brainstorming Session",
//       CompanyName: "XYZ Inc.",
//       Participant1: "Alice Smith",
//       Participant2: "Sarah Jones",
//       Participant3: "Michael Brown",
//     },
//     {
//       select: false, // Checkbox initially unchecked
//       Sr: 3,
//       MeetingName: "Client Review Meeting",
//       CompanyName: "MNO Studios",
//       Participant1: "Michael Brown",
//       Participant2: "David Lee",
//       Participant3: "Sarah Jones",
//     },
//   ]);

//   const handleSelectAll = (e) => {
//     const checked = e.target.checked;
//     settdata((prevTdata) =>
//       prevTdata.map((item) => ({
//         ...item,
//         select: checked,
//       }))
//     );
//   };

//   const handleSelectOne = (index) => {
//     settdata((prevTdata) => prevTdata.map((item, i) => (i === index ? { ...item, select: !item.select } : item)));
//   };

//   const [userGridData, setUserGridData] = useState({
//     columns: [
//       {
//         label: "#",
//         field: "Sr",
//         sort: "asc",
//         width: 150,
//       },

//       {
//         label: "Meeting Name",
//         field: "meeting_name",
//         sort: "asc",
//         width: 150,
//       },
//       {
//         label: "Company Name",
//         field: "company_name",
//         sort: "asc",
//         width: 100,
//       },
//       {
//         label: "Participant 1",
//         field: "Participant1",
//         sort: "asc",
//         width: 100,
//       },
//       {
//         label: "Participant 2",
//         field: "Participant2",
//         sort: "asc",
//         width: 100,
//       },
//       {
//         label: "Participant 3",
//         field: "Participant3",
//         width: 100,
//         sort: "asc",
//       },
//       {
//         label: "Actions",
//         field: "action",
//         width: 100,
//         sort: "asc",
//       },
//     ],
//     rows: [],
//   });

//   useEffect(() => {
//     setUserGridData((prevData) => ({
//       ...prevData,
//       rows: tdata.map((item, i) => ({
//         ...item,
//         Sr: i + 1,
//         select: <input type="checkbox" className="form-check-input ms-2" checked={item.select} onChange={() => handleSelectOne(i)} />,
//         action: (
//           <div className="icon-container">
//             <DeleteForever className="icon delete-icon" />
//             <Edit className="icon visibility-icon" />
//             <Download className="icon download-icon" />
//           </div>
//         ),
//       })),
//     }));
//   }, [tdata]);

//   useEffect(() => {
//     dispatch(getAllMeetings());
//   }, []);

//   return (
//     <div div className="main-container w-100">
//       <Breadcrumb items={breadcrumbItems.savedMeetings} />
//       <MDBContainer className="d-flex justify-content-center align-items-center row ms-auto me-auto">
//         <Loader isLoading={false} />
//         <MotionAnimate animation="fadeInUp" reset={false} distance={50} delay={0.4} speed={0.5}>
//           <MDBRow className="w-100 col-12">
//             <MDBCol className="d-flex justify-content-center w-100">
//               <MDBCard className="w-100 p-3">
//                 <MDBCardBody>
//                   <MDBCardTitle className="mb-3">List Saved Meetings</MDBCardTitle>
//                   <MDBDataTable responsive noBottomColumns entriesLabel="Show Meetings" infoLabel={["Showing", "to", "of", "Meetings"]} striped small data={userGridData} />
//                 </MDBCardBody>
//               </MDBCard>
//             </MDBCol>
//           </MDBRow>
//         </MotionAnimate>
//       </MDBContainer>
//     </div>
//   );
// };

// export default SavedMeetings;

import React, { useEffect, useState } from "react";
import { MotionAnimate } from "react-motion-animate";
import Loader from "../components/Loader";
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";

import { MDBDataTable } from "mdbreact";
import { Edit, Download, DeleteForever } from "@mui/icons-material";
import Breadcrumb from "../components/common/BreadCrumb";
import { breadcrumbItems } from "../constants/breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { getAllMeetings } from "../redux/slices/meetingSlice";

const SavedMeetings = () => {
  const dispatch = useDispatch();
  const savedMeetings = useSelector((state) => state.meeting.allSavedMeetings);
  const isLoading = useSelector((state) => state.meeting.isLoading);

  const [userGridData, setUserGridData] = useState({
    columns: [
      {
        label: "#",
        field: "Sr",
        sort: "asc",
        width: 150,
      },
      {
        label: "Meeting Name",
        field: "meeting_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Company Name",
        field: "company_name",
        sort: "asc",
        width: 100,
      },
      {
        label: "Participant 1",
        field: "Participant1",
        sort: "asc",
        width: 100,
      },
      {
        label: "Participant 2",
        field: "Participant2",
        sort: "asc",
        width: 100,
      },
      {
        label: "Participant 3",
        field: "Participant3",
        width: 100,
        sort: "asc",
      },
      {
        label: "Actions",
        field: "action",
        width: 100,
        sort: "asc",
      },
    ],
    rows: [],
  });

  const handleSelectOne = (index) => {
    setUserGridData((prevData) => ({
      ...prevData,
      rows: prevData.rows.map((item, i) => (i === index ? { ...item, select: !item.select } : item)),
    }));
  };

  useEffect(() => {
    dispatch(getAllMeetings());
  }, [dispatch]);

  useEffect(() => {
    if (savedMeetings?.length > 0) {
      const formattedData = savedMeetings.map((meeting, index) => {
        const participants = meeting.participants || [];
        return {
          Sr: index + 1,
          meeting_name: meeting.meeting_name,
          company_name: meeting.company_name,
          Participant1: participants[0]?.participant_name || "",
          Participant2: participants[1]?.participant_name || "",
          Participant3: participants[2]?.participant_name || "",
          select: <input type="checkbox" className="form-check-input ms-2" checked={meeting.select || false} onChange={() => handleSelectOne(index)} />,
          action: (
            <div className="icon-container">
              <DeleteForever className="icon delete-icon" />
              <Edit className="icon visibility-icon" />
              <Download className="icon download-icon" />
            </div>
          ),
        };
      });

      setUserGridData((prevData) => ({
        ...prevData,
        rows: formattedData,
      }));
    }
  }, [savedMeetings]);

  return (
    <div className="main-container w-100">
      <Breadcrumb items={breadcrumbItems.savedMeetings} />
      <MDBContainer className="d-flex justify-content-center align-items-center row ms-auto me-auto">
        <Loader isLoading={isLoading} />
        <MotionAnimate animation="fadeInUp" reset={false} distance={50} delay={0.4} speed={0.5}>
          <MDBRow className="w-100 col-12">
            <MDBCol className="d-flex justify-content-center w-100">
              <MDBCard className="w-100 p-3">
                <MDBCardBody>
                  <MDBCardTitle className="mb-3">List Saved Meetings</MDBCardTitle>
                  <MDBDataTable responsive noBottomColumns entriesLabel="Show Meetings" infoLabel={["Showing", "to", "of", "Meetings"]} striped small data={userGridData} />
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        </MotionAnimate>
      </MDBContainer>
    </div>
  );
};

export default SavedMeetings;
