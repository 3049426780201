import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { uploadCsvService } from "../services/services";

export const uploadCsv = createAsyncThunk("meeting/uploadCsv", async (data, { rejectWithValue }) => {
  try {
    const response = await uploadCsvService(data);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

const meetingSlice = createSlice({
  name: "csv",
  initialState: {
    isLoading: false,
    error: null,
    uploadCsv: null,
  },
  reducers: {
    resetCsvStates: (state, action) => {
      state.uploadCsv = null;
      state.error = [];
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadCsv.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(uploadCsv.fulfilled, (state, action) => {
        state.isLoading = false;
        state.uploadCsv = action.payload;
      })
      .addCase(uploadCsv.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { resetCsvStates } = meetingSlice.actions;

export default meetingSlice.reducer;
