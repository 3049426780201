import { configureStore } from "@reduxjs/toolkit";
import meetingReducer from "./../slices/meetingSlice";
import csvReducer from "./../slices/uploadCsvSlice";
import favoriteQuestionsReducer from "./../slices/favoriteQuestionsSlice";
import authReducer from "./../slices/authSlice";

const store = configureStore({
  reducer: {
    meeting: meetingReducer,
    csv: csvReducer,
    favorite: favoriteQuestionsReducer,
    auth: authReducer,
  },
});

export default store;
