import React, { useEffect, useState } from "react";
import { MDBBtn, MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBCardImage, MDBInput, MDBIcon } from "mdb-react-ui-kit";
import { useNavigate } from "react-router-dom";
import { routes } from "../../config/routes";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../redux/slices/authSlice";
import { validateLogin } from "../../validator/authValidator";
import { PuffLoader } from "react-spinners";

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state?.auth?.isAuthenticated);
  const userType = useSelector((state) => state.auth.userType);
  console.log(userType + "login");
  const isLoading = useSelector((state) => state.auth.isLoading);
  const user = useSelector((state) => state.auth.user);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState({});

  const handleLogin = () => {
    const errors = validateLogin(email, password);
    if (errors === null) {
      setErrorMessage([]);
      const payload = {
        email,
        password,
      };
      dispatch(login(payload));
    } else {
      setErrorMessage(errors);
    }
  };

  useEffect(() => {
    if (user?.status === "Success") {
      userType === "Admin" ? navigate(`/admin/${routes.panel.adminPanel.Dashboard.path}`) : navigate(`/user/${routes.panel.userPanel.Dashboard.path}`);
    } else if (user?.status === "Error") {
      setErrorMessage({ password: user?.message });
    }
  }, [user]);

  return (
    <MDBContainer fluid className="d-flex align-items-center justify-content-center" style={{ minHeight: "100vh", backgroundColor: "#EEEEEE" }}>
      <MDBCard className="text-black m-5 col-6 pt-5 pb-5" style={{ borderRadius: "25px" }}>
        <MDBCardBody>
          <MDBRow>
            <MDBCol md="10" lg="6" className="order-2 order-lg-1 d-flex flex-column align-items-center">
              <h3 className="text-center h1 fw-bold mb-5 mx-1 mx-md-4 mt-4" style={{ fontWeight: "700", fontSize: "2.5rem" }}>
                Login
              </h3>

              <div className="d-flex flex-row align-items-center  mt-4" style={{ width: "80%" }}>
                <MDBIcon fas icon="envelope me-3" size="lg" />
                <MDBInput label="Your Email" id="form2" type="email" size="lg" autoComplete="off" value={email} onChange={(e) => setEmail(e.target.value)} />
              </div>
              <div className="d-flex flex-row align-items-center" style={{ width: "80%" }}>
                {errorMessage?.email && (
                  <small className="text-danger " style={{ marginLeft: "9%" }}>
                    {errorMessage?.email}
                  </small>
                )}
              </div>

              <div className="d-flex flex-row align-items-center mt-4 " style={{ width: "80%" }}>
                <MDBIcon fas icon="lock me-3" size="lg" />
                <MDBInput label="Password" id="form3" size="lg" type="password" autoComplete="off" value={password} onChange={(e) => setPassword(e.target.value)} />
              </div>
              <div className="d-flex flex-row align-items-center" style={{ width: "80%" }}>
                {errorMessage?.password && (
                  <small className="text-danger " style={{ marginLeft: "9%" }}>
                    {errorMessage?.password}
                  </small>
                )}
              </div>

              <div className="d-flex flex-row align-items-center mb-3" style={{ width: "80%", paddingLeft: "6%" }}>
                <MDBBtn className="mb-4 mt-4 ml-5 w-100" size="lg" color="secondary" onClick={handleLogin}>
                  <span className="d-flex flex-row align-items-center justify-content-center g-p">{isLoading ? <PuffLoader color="orange" className="me-3" size={25} /> : <span>Login</span>}</span>
                </MDBBtn>
              </div>
              <div className="d-flex flex-row justify-content-center " role="button">
                <p className="text-center text-muted  mb-0">Don't have an account?</p>
                <p onClick={() => navigate(routes.auth.signUp.path)} className="ms-2 text-decoration-underline fw-bold text-body">
                  Sign Up
                </p>
              </div>
              <div className="d-flex flex-row justify-content-center  " role="button">
                <p className="text-center text-muted  mb-0">Don't remember your password?</p>
                <p onClick={() => navigate(routes.auth.forgotPassword.path)} className="ms-2 text-decoration-underline fw-bold text-body">
                  Forgot Password
                </p>
              </div>
            </MDBCol>

            <MDBCol md="10" lg="6" className="order-1 order-lg-2 d-flex align-items-center">
              <MDBCardImage src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-registration/draw1.webp" fluid />
            </MDBCol>
          </MDBRow>
        </MDBCardBody>
      </MDBCard>
    </MDBContainer>
  );
}

export default Login;
