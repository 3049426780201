import { Favorite } from "@mui/icons-material";
import CreateMeeting from "../pages/CreateMeeting";
import ForgotPassword from "../pages/auth/ForgotPassword";
import Login from "../pages/auth/Login";
import SignUp from "../pages/auth/SignUp";
import SavedMeetings from "../pages/SavedMeetings";
import MyFavoriteQuestions from "../pages/MyFavoriteQuestions";
import ImportFiles from "../pages/ImportFiles";
import Dashboard from "../pages/Dashboard";

export const routes = {
  dashboard: "",
  error: "/404",

  auth: {
    login: {
      path: "/login",
      component: Login,
    },
    signUp: {
      path: "/sign-up",
      component: SignUp,
    },
    forgotPassword: {
      path: "/forgot-password",
      component: ForgotPassword,
    },
  },
  panel: {
    adminPanel: {
      Dashboard: {
        path: "dashboard",
        component: Dashboard,
      },
      createMeeting: {
        path: "create-meeting",
        component: CreateMeeting,
      },
      savedMeetings: {
        path: "saved-meetings",
        component: SavedMeetings,
      },
      favoriteQuestons: {
        path: "favorite-questions",
        component: MyFavoriteQuestions,
      },
      addFiles: {
        path: "add-questions-file",
        component: ImportFiles,
      },
    },
    userPanel: {
      Dashboard: {
        path: "dashboard",
        component: Dashboard,
      },
      createMeeting: {
        path: "create-meeting",
        component: CreateMeeting,
      },
      savedMeetings: {
        path: "saved-meetings",
        component: SavedMeetings,
      },
      favoriteQuestons: {
        path: "favorite-questions",
        component: MyFavoriteQuestions,
      },
    },
  },
};
