import React from "react";
import { MDBCardImage } from "mdb-react-ui-kit";
import { useLocation } from "react-router-dom";
import { height } from "@mui/system";
import Breadcrumb from "../components/common/BreadCrumb";

const Dashboard = () => {
  const location = useLocation();
  const path = location.pathname.split("/");
  const panel = path[1];
  const route = path[2];

  const breadcrumbItems = [{ name: "Dashboard", path: "/", icon: "Dashboard" }];

  return (
    <div style={styles.mainContainer}>
      <Breadcrumb items={breadcrumbItems} />

      <div style={styles.container}>
        {panel === "user" ? (
          <MDBCardImage style={{ width: "500px", height: "500px" }} src="https://cdni.iconscout.com/illustration/premium/thumb/admin-panel-4439228-3728455.png?f=webp" />
        ) : (
          <MDBCardImage style={{ width: "500px" }} src="https://images.ctfassets.net/fevtq3bap7tj/56jC03w0BXujIPxykHIMgJ/78f64f8a7dda0497a344c22346d02379/dashboards-illus_2x.svg" />
        )}
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // width: "100%",
    // flexDirection: "column",
    height: "100%",
  },
  mainContainer: {
    display: "flex",
    // justifyContent: "center",
    // alignItems: "center",
    width: "100%",
    flexDirection: "column",
  },
};

export default Dashboard;
