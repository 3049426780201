import React, { useEffect, useState } from "react";
import { MotionAnimate } from "react-motion-animate";
import Loader from "../components/Loader";
import { MDBBadge, MDBBtn, MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBCollapse, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import { Typewriter } from "react-simple-typewriter";
import { MDBDataTable } from "mdbreact";
import { colourOptions } from "./../constants/data";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { Edit, Download, DeleteForever } from "@mui/icons-material";
import Breadcrumb from "../components/common/BreadCrumb";
import { breadcrumbItems } from "../constants/breadcrumb";

const animatedComponents = makeAnimated();

const MyFavoriteQuestions = () => {
  const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRtl, setIsRtl] = useState(false);
  const [participants, setParticipants] = useState([]);
  const [value, onChange] = useState(new Date());
  const [isSubmit, setIsSubmit] = useState(false);
  const [tdata, settdata] = useState([
    {
      select: false, // Checkbox initially unchecked
      Sr: 1,
      question: "What are the causes of the French Revolution?",
      filterby: "Sales Stage, Objective Sought",
    },
    {
      select: false, // Checkbox initially unchecked
      Sr: 2,
      question: "Describe the process of photosynthesis",
      filterby: "Objective Sought,  Relationship Status",
    },
    {
      select: false, // Checkbox initially unchecked
      Sr: 3,
      question: "What are the different parts of a plant cell?",
      filterby: "Objective Sought, Relationship Status, Rating",
    },
  ]);

  const handleSelectAll = (e) => {
    const checked = e.target.checked;
    settdata((prevTdata) =>
      prevTdata.map((item) => ({
        ...item,
        select: checked,
      }))
    );
  };

  const handleSelectOne = (index) => {
    settdata((prevTdata) => prevTdata.map((item, i) => (i === index ? { ...item, select: !item.select } : item)));
  };

  const [userGridData, setUserGridData] = useState({
    columns: [
      {
        label: "#",
        field: "Sr",
        sort: "asc",
        width: 150,
      },
      {
        label: "Questions",
        field: "question",
        sort: "asc",
        width: 150,
      },
      {
        label: "Filtered By",
        field: "filterby",
        sort: "asc",
        width: 100,
      },
      {
        label: "Actions",
        field: "action",
        width: 100,
        sort: "asc",
      },
    ],
    rows: [],
  });

  useEffect(() => {
    setUserGridData((prevData) => ({
      ...prevData,
      rows: tdata.map((item, i) => ({
        ...item,
        Sr: i + 1,
        select: <input type="checkbox" className="form-check-input ms-2" checked={item.select} onChange={() => handleSelectOne(i)} />,
        action: (
          <div className="icon-container">
            <DeleteForever className="icon delete-icon" />
            <Edit className="icon visibility-icon" />
            {/* <Download className="icon download-icon" /> */}
          </div>
        ),
      })),
    }));
  }, [tdata]);

  const isAnySelected = tdata.some((item) => item.select);

  return (
    <div div className="main-container w-100">
      <Breadcrumb items={breadcrumbItems.myFavoriteQuestions} />
      <MDBContainer className="d-flex justify-content-center align-items-center row ms-auto me-auto">
        <Loader isLoading={false} />
        <MotionAnimate animation="fadeInUp" reset={false} distance={50} delay={0.4} speed={0.5}>
          <MDBRow className="w-100  col-12 ">
            <MDBCol>
              <MDBCard className="w-100 p-3">
                <MDBCardBody>
                  <MDBCardTitle className="mb-3">Filter Questions By:{/* <MDBBadge className="mx-2" color="success" light></MDBBadge> */}</MDBCardTitle>

                  <div className=" row mb-4 g-3">
                    <div className="col-4 ">
                      <label className="select-participant-label">Select Sales Stage:</label>
                      <Select className="basic-single" components={animatedComponents} classNamePrefix="select" defaultValue={colourOptions[0]} isDisabled={isDisabled} isLoading={isLoading} isClearable={isClearable} isRtl={isRtl} isSearchable={isSearchable} name="color" options={colourOptions} />
                    </div>
                    <div className="col-4 pl-2">
                      <label className="select-participant-label">Select Objective Sought:</label>
                      <Select className="basic-single" classNamePrefix="select" defaultValue={colourOptions[0]} isDisabled={isDisabled} isLoading={isLoading} isClearable={isClearable} isRtl={isRtl} isSearchable={isSearchable} name="color" options={colourOptions} />
                    </div>

                    <div className="col-4 ">
                      <label className="select-participant-label">Select Participant Title:</label>
                      <Select className="basic-single" classNamePrefix="select" defaultValue={colourOptions[0]} isDisabled={isDisabled} isLoading={isLoading} isClearable={isClearable} isRtl={isRtl} isSearchable={isSearchable} name="color" options={colourOptions} />
                    </div>
                    <div className="col-4 pl-2">
                      <label className="select-participant-label">Select Participant Role:</label>
                      <Select className="basic-single" classNamePrefix="select" defaultValue={colourOptions[0]} isDisabled={isDisabled} isLoading={isLoading} isClearable={isClearable} isRtl={isRtl} isSearchable={isSearchable} name="color" options={colourOptions} />
                    </div>

                    <div className="col-4 ">
                      <label className="select-participant-label">Select Relationship Status:</label>
                      <Select className="basic-single" classNamePrefix="select" defaultValue={colourOptions[0]} isDisabled={isDisabled} isLoading={isLoading} isClearable={isClearable} isRtl={isRtl} isSearchable={isSearchable} name="color" options={colourOptions} />
                    </div>
                    <div className="col-2 pl-2">
                      <label className="select-participant-label">Select Tone:</label>
                      <Select className="basic-single" classNamePrefix="select" defaultValue={colourOptions[0]} isDisabled={isDisabled} isLoading={isLoading} isClearable={isClearable} isRtl={isRtl} isSearchable={isSearchable} name="color" options={colourOptions} />
                    </div>

                    <div className="col-2">
                      <label className="select-participant-label">Select Rating:</label>
                      <Select className="basic-single" classNamePrefix="select" defaultValue={colourOptions[0]} isDisabled={isDisabled} isLoading={isLoading} isClearable={isClearable} isRtl={isRtl} isSearchable={isSearchable} name="color" options={colourOptions} />
                    </div>
                  </div>
                  <MDBBtn className="mt-3 " color="dark">
                    Search
                  </MDBBtn>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        </MotionAnimate>
        <MotionAnimate animation="fadeInUp" reset={false} distance={50} delay={0.4} speed={0.5}>
          <MDBRow className="w-100 col-12 mt-5 mb-5">
            <MDBCol className="d-flex justify-content-center w-100">
              <MDBCard className="w-100 p-3">
                <MDBCardBody>
                  <MDBCardTitle className="mb-3">
                    <Typewriter words={["My Favorite Questions"]} />
                  </MDBCardTitle>
                  <MDBDataTable responsive noBottomColumns entriesLabel="Show Questions" infoLabel={["Showing", "to", "of", "Questions"]} striped small data={userGridData} />

                  <MDBRow className="w-100 col-12 m-0 p-0">
                    <MDBCol className="d-flex w-100 m-0 p-0">
                      {/* <MDBBtn className="mt-5  " color="dark">
                      Back
                    </MDBBtn> */}
                      {isAnySelected && (
                        <MotionAnimate animation="fadeInUp" reset={false} distance={50} delay={0.4} speed={0.5}>
                          <MDBBtn className="mt-5  me-4 ms-4" color="dark">
                            Remove
                          </MDBBtn>
                          <MDBBtn className="mt-5  " color="dark">
                            export
                          </MDBBtn>
                        </MotionAnimate>
                      )}
                    </MDBCol>
                  </MDBRow>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        </MotionAnimate>
      </MDBContainer>
    </div>
  );
};

export default MyFavoriteQuestions;
