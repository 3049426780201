import React from "react";
import { MDBBtn, MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBCardImage, MDBInput, MDBIcon } from "mdb-react-ui-kit";
import { useNavigate } from "react-router-dom";
import { routes } from "../../config/routes";

function ForgotPassword() {
  const navigate = useNavigate();

  return (
    <MDBContainer fluid className="d-flex align-items-center justify-content-center" style={{ minHeight: "100vh", backgroundColor: "#EEEEEE" }}>
      <MDBCard className="text-black m-5 col-6 pt-5 pb-5" style={{ borderRadius: "25px" }}>
        <MDBCardBody>
          <MDBRow>
            <div className="w-100 d-flex justify-content-center">
              <span classNAme="text-center h1 fw-bold mb-5 mx-1 mx-md-4 mt-4 " style={{ fontWeight: "700", fontSize: "2.5rem" }}>
                Forgot Your Password?
              </span>
            </div>

            <MDBCol md="10" lg="6" className="order-2 order-lg-1 d-flex flex-column align-items-center">
              <div className="d-flex flex-row align-items-center mb-4 mt-4" style={{ width: "80%" }}>
                <MDBIcon fas icon="envelope me-3" size="lg" />
                <MDBInput label="Your Email" id="form2" type="email" size="lg" autocomplete="off" />
              </div>

              <div className="d-flex flex-row align-items-center mb-4" style={{ width: "80%", paddingLeft: "6%" }}>
                <MDBBtn className="mb-4 mt-4 ml-5 w-100" size="lg" color="secondary">
                  Forgot Password
                </MDBBtn>
              </div>

              <div className="d-flex flex-row justify-content-center mb-4">
                <p className="text-center text-muted mb-0">Remembered your password?</p>
                <p onClick={() => navigate(routes.auth.login.path)} className="ms-2 text-decoration-underline fw-bold text-body" role="button">
                  Login
                </p>
              </div>
            </MDBCol>

            <MDBCol md="10" lg="6" className="order-1 order-lg-2 d-flex align-items-center">
              <MDBCardImage src="https://media.istockphoto.com/id/1372135382/vector/account-access-vector-illustration-in-flat-design.jpg?s=612x612&w=0&k=20&c=z3e6g9tRUYTlOIxROy0QOSjT3HhGrByzhZaxChCVHEA=" fluid />
            </MDBCol>
          </MDBRow>
        </MDBCardBody>
      </MDBCard>
    </MDBContainer>
  );
}

export default ForgotPassword;
