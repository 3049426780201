import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAllFavoriteQuestionsService, markAsFavoriteService } from "../services/services";

export const markAsFavorite = createAsyncThunk("favorite/markAsFavorite", async (data, { rejectWithValue }) => {
  try {
    const response = await markAsFavoriteService(data);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const getAllFavoriteQuestions = createAsyncThunk("favorite/getAllFavoriteQuestions", async (_, { rejectWithValue }) => {
  try {
    const response = await getAllFavoriteQuestionsService();
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

const favoriteQuestionsSlice = createSlice({
  name: "favorite",
  initialState: {
    isLoading: false,
    error: null,
    addToFavorite: null,
    AllFavoriteQuestions: [],
  },
  reducers: {
    resetFavoriteStates: (state, action) => {
      state.addToFavorite = null;
      state.error = null;
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(markAsFavorite.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(markAsFavorite.fulfilled, (state, action) => {
        state.isLoading = false;
        state.addToFavorite = action.payload;
      })
      .addCase(markAsFavorite.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(getAllFavoriteQuestions.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getAllFavoriteQuestions.fulfilled, (state, action) => {
        state.isLoading = false;
        state.AllFavoriteQuestions = action.payload.data;
      })
      .addCase(getAllFavoriteQuestions.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { resetFavoriteStates } = favoriteQuestionsSlice.actions;

export default favoriteQuestionsSlice.reducer;
