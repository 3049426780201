import React, { useState, useEffect } from "react";
import { MDBBtn, MDBTable, MDBTableHead, MDBTableBody, MDBCheckbox, MDBCard, MDBCardBody, MDBCardTitle, MDBRow, MDBCol } from "mdb-react-ui-kit";
import { Grade, Spa, StarOutline } from "@mui/icons-material";
import "./../styles/common.css";
import { Typewriter } from "react-simple-typewriter";

import Swal from "sweetalert2";
import { PuffLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import { markAsFavorite, resetFavoriteStates } from "../redux/slices/favoriteQuestionsSlice";

const DataTable = ({ data, name, getSelectedQuestions, index, handleRefreshSelectedQuestions, status }) => {
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [visibleQuestions, setVisibleQuestions] = useState([]);
  const [isRefreshDisabled, setIsRefreshDisabled] = useState(true);
  const [allSkippedQuestions, setAllSkipedQuestions] = useState([]);
  const [question, setQuestion] = useState("");
  const [qIndex, setQIndex] = useState(null);
  const [qId, setQId] = useState();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state?.favorite?.isLoading);
  const addToFavorite = useSelector((state) => state?.favorite?.addToFavorite);

  const handleQuestionSelect = (questionId) => {
    const isSelected = selectedQuestions.includes(questionId);
    if (isSelected) {
      setSelectedQuestions(selectedQuestions.filter((id) => id !== questionId));
    } else {
      setSelectedQuestions([...selectedQuestions, questionId]);
    }
  };

  const handleRefresh = () => {
    const remainingQuestions = visibleQuestions.filter((question) => !selectedQuestions.includes(question.question_id));
    setVisibleQuestions(remainingQuestions);
    handleRefreshSelectedQuestions(index, selectedQuestions.length, [...allSkippedQuestions, ...selectedQuestions]);
    setAllSkipedQuestions([...allSkippedQuestions, ...selectedQuestions]);
    setSelectedQuestions([]);
  };

  const handleReset = () => {
    setQuestion("");
    setQIndex("");
    setQId("");
    dispatch(resetFavoriteStates());
  };

  const handleFavorite = (question, i, status) => {
    setQuestion(question.question_text);
    setQId(question.question_id);
    setQIndex(i);
    const payload = {
      question_id: question?.question_id,
      favorite: status,
    };
    dispatch(markAsFavorite(payload));
  };

  useEffect(() => {
    setVisibleQuestions([...data]);
    //
  }, [data]);

  useEffect(() => {
    setIsRefreshDisabled(selectedQuestions.length === 0);
  }, [selectedQuestions]);

  useEffect(() => {
    getSelectedQuestions(visibleQuestions, index);
  }, [visibleQuestions]);

  useEffect(() => {
    if (status) {
      setAllSkipedQuestions([]);
      setSelectedQuestions([]);
      setVisibleQuestions([]);
    }
  }, [status]);

  useEffect(() => {
    if (addToFavorite?.status === "Success") {
      Swal.fire({
        title: addToFavorite.message,
        text: `Question: ${question}`,
        icon: "success",
      });

      // Update the visibleQuestions array locally
      setVisibleQuestions((prevQuestions) => {
        return prevQuestions.map((q, index) => {
          if (index === qIndex) {
            return { ...q, is_favorite: q.is_favorite === "Y" ? "N" : "Y" };
          }
          return q;
        });
      });

      handleReset();
    }
  }, [addToFavorite]);

  return (
    <>
      <div className="row w-100 mt-5">
        <MDBCard className="w-100 p-3 col-12 mt-5">
          <MDBCardBody>
            <MDBCardTitle className="mb-3">
              <Typewriter words={[name]} />
            </MDBCardTitle>
            <MDBTable align="middle">
              <MDBTableHead light>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Select</th>
                  <th scope="col">Questions </th>
                  <th scope="col" className="text-center">
                    Favorite
                  </th>
                </tr>
              </MDBTableHead>
              <MDBTableBody>
                {visibleQuestions.map((question, i) => (
                  <tr key={i}>
                    <th scope="col">{i + 1}</th>
                    <th scope="col">
                      <MDBCheckbox checked={selectedQuestions.includes(question.question_id)} onChange={() => handleQuestionSelect(question.question_id)} />
                    </th>
                    <td>{question.question_text}</td>
                    <td className="text-center  d-flex justify-content-center w-100">
                      {question?.is_favorite === "Y" ? (
                        <span style={{ cursor: "pointer" }} onClick={() => handleFavorite(question, i, "N")}>
                          <Grade sx={{ color: "orange" }} />
                        </span>
                      ) : (
                        <span style={{ cursor: "pointer" }} onClick={() => handleFavorite(question, i, "Y")}>
                          {isLoading && qIndex === i ? (
                            <div>
                              <PuffLoader color="orange" size={30} />
                            </div>
                          ) : (
                            <StarOutline sx={{ color: "orange" }} />
                          )}
                        </span>
                      )}
                    </td>
                  </tr>
                ))}
              </MDBTableBody>
            </MDBTable>
            <MDBRow className="w-100 col-12 m-0 p-0">
              <MDBCol className="d-flex w-100  p-0">
                <MDBBtn className="mt-5" color="dark" onClick={handleRefresh} disabled={isRefreshDisabled}>
                  Refresh The Selected Questions
                </MDBBtn>
              </MDBCol>
            </MDBRow>
            {/* {isModalOpen && <ConfirmationModal bodyContent={question} title={isRemove ? "Are you sure you want to Remove this question from favorites?" : "Are you sure you want to add this question to favorites?"} onConfirm={handleConfirm} onCancel={handleCancel} />} */}
          </MDBCardBody>
        </MDBCard>
      </div>
    </>
  );
};

export default DataTable;
