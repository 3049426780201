import React from "react";
import { DotLoader } from "react-spinners";

const Loader = ({ isLoading }) => {
  return (
    <div className={`loader-overlay ${isLoading ? "visible" : "hidden"}`}>
      <div className="loader-container">
        <DotLoader color="#36D7B7" loading={isLoading} size={150} />
      </div>
    </div>
  );
};

export default Loader;
