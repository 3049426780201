import React from "react";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Home, Add, Save, Favorite, Dashboard, FileUpload, List, StarOutline } from "@mui/icons-material";
import { MDBBtn, MDBCardImage } from "mdb-react-ui-kit";
import { useNavigate, useLocation } from "react-router-dom";
import { routes } from "../config/routes";
import "./../styles/sideBar.css";
import logo from "./../assets/1310191.png";

const MySidebarMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const path = location.pathname.split("/");
  const panel = path[1];
  const route = path[2];

  return (
    <Sidebar style={{ height: "100vh", display: "flex", flexDirection: "column", alignItems: "stretch" }}>
      <Menu className="mt-3 ">
        <div className="d-flex align-items-center mb-3 w-100 ps-4">
          <MDBCardImage src={logo} style={{ height: "30px", width: "auto", marginTop: "-5px" }} />
          <span className="ms-2">The Sales Chief</span>
        </div>
        <MenuItem
          icon={<Dashboard />}
          className="mb-4 mt-4"
          onClick={() => navigate(routes.panel.adminPanel.Dashboard.path)}
          style={{ backgroundColor: route === routes.panel.adminPanel.Dashboard.path ? "#404751" : "transparent", color: route === routes.panel.adminPanel.Dashboard.path ? "#f5fcfe" : "#000" }}
        >
          Dashboard
        </MenuItem>

        <div className=" border border-secondary mb-3"></div>

        {panel === "admin" && (
          <MenuItem
            icon={<FileUpload />}
            className="mb-2 "
            onClick={() => navigate(routes.panel.adminPanel.addFiles.path)}
            style={{ backgroundColor: route === routes.panel.adminPanel.addFiles.path ? "#404751" : "transparent", color: route === routes.panel.adminPanel.addFiles.path ? "#f5fcfe" : "#000" }}
          >
            Upload Questions File
          </MenuItem>
        )}

        <MenuItem
          icon={<Add />}
          className="mb-2 "
          onClick={() => navigate(routes.panel.userPanel.createMeeting.path)}
          style={{ backgroundColor: route === routes.panel.userPanel.createMeeting.path ? "#404751" : "transparent", color: route === routes.panel.userPanel.createMeeting.path ? "#f5fcfe" : "#000" }}
        >
          Create a new meeting
        </MenuItem>
        <MenuItem
          icon={<List />}
          className="mb-2 "
          onClick={() => navigate(routes.panel.userPanel.savedMeetings.path)}
          style={{ backgroundColor: route === routes.panel.userPanel.savedMeetings.path ? "#404751" : "transparent", color: route === routes.panel.userPanel.savedMeetings.path ? "#f5fcfe" : "#000" }}
        >
          Saved Meetings
        </MenuItem>
        <MenuItem
          icon={<StarOutline />}
          className="mb-2"
          onClick={() => navigate(routes.panel.userPanel.favoriteQuestons.path)}
          style={{ backgroundColor: route === routes.panel.userPanel.favoriteQuestons.path ? "#404751" : "transparent", color: route === routes.panel.userPanel.favoriteQuestons.path ? "#f5fcfe" : "#000" }}
        >
          My Favorite Questions
        </MenuItem>
      </Menu>

      <MDBBtn className="me-1 mb-3 m-2" color="danger" onClick={() => navigate(routes.auth.login.path)}>
        Logout
      </MDBBtn>
    </Sidebar>
  );
};

export default MySidebarMenu;
