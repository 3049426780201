import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { MDBCard, MDBCardBody, MDBCardTitle, MDBBtn, MDBBadge, MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import makeAnimated from "react-select/animated";
import { Typewriter } from "react-simple-typewriter";
import { MotionAnimate } from "react-motion-animate";
import Loader from "../components/Loader";
import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import DataTable from "../components/DataTable";
import { TagsInput } from "react-tag-input-component";
import Breadcrumb from "../components/common/BreadCrumb";
import { breadcrumbItems } from "../constants/breadcrumb";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { createNewMeeting, getAllCategories, getQuestions, resetCreateMeeting, resetQuestions } from "../redux/slices/meetingSlice";
import { ToastContainer, toast } from "react-toastify";
import { newMeetingDataValidator, searchValidator } from "../validator/createMeetingValidation";

const animatedComponents = makeAnimated();
const limit = 5;
const CreateMeeting = () => {
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.meeting.categories);
  const questions = useSelector((state) => state.meeting.questions);
  const createMeeting = useSelector((state) => state.meeting.createNewMeeting);
  const isLoading = useSelector((state) => state.meeting.isLoading);

  const [participants, setParticipants] = useState([]);
  const [participantData, setParticipantData] = useState([]);
  const [particpantQuestions, setParticipantQuestions] = useState({});
  const [letesSearchingIndex, setLetesSearchingIndex] = useState();
  const [validationError, setValidationError] = useState({});
  const [searchErrors, setSearchErrors] = useState([]);
  const [meeting, setMeeting] = useState({
    companyName: "",
    name: "",
    date: new Date(),
  });

  const newMeetingRef = useRef(null);

  const handleCategoryChange = (participantIndex, category, selectedOption) => {
    const updatedData = [...participantData];
    updatedData[participantIndex] = { ...updatedData[participantIndex], [category]: selectedOption };
    setParticipantData(updatedData);
  };

  const handleMeetingNameChange = (e) => {
    setMeeting({ ...meeting, name: e.target.value });
  };

  const handleDateChange = (date) => {
    setMeeting({ ...meeting, date: date });
  };

  const handleCompanyNameChange = (e) => {
    setMeeting({ ...meeting, companyName: e.target.value });
  };

  const handleChange = (newTags) => {
    const filteredTags = newTags.filter((tag) => tag.length <= 50);

    const removedParticipants = participants.filter((participant) => !filteredTags.includes(participant));
    const updatedParticipantData = participantData.filter((_, index) => !removedParticipants.includes(participants[index]));

    setParticipants(filteredTags);
    setParticipantData(updatedParticipantData);
  };

  const handleSubmit = () => {
    const formattedDate = moment(meeting?.date).format("YYYY-MM-DD HH:mm:ss");
    const payload = {
      meeting_date_time: formattedDate,
      meeting_name: meeting?.name,
      company_name: meeting?.companyName,
      participants:
        participants?.map((participantName, index) => {
          const participant = participantData?.[index];
          const searchCriteria = Object.values(participant ?? {})
            .filter((value) => value && value.keyword_id && value.category_id)
            .map((value) => ({
              keyword_id: value.keyword_id,
              category_id: value.category_id,
            }));
          const questionIds = participant?.questions?.map((question) => question.question_id) ?? [];
          return {
            participants_name: participantName,
            job_title: participant?.jobTitle,
            search_criteria: searchCriteria,
            question_ids: questionIds,
          };
        }) ?? [],
    };
    const errors = newMeetingDataValidator(payload, categories);

    if (errors === null) {
      setValidationError({});
      dispatch(createNewMeeting(payload));
    } else {
      setValidationError(errors);
      if (newMeetingRef.current) {
        newMeetingRef.current.scrollTo({ top: 0, behavior: "smooth" });
      }
    }
  };

  const handleSearch = (participantIndex, limitSkiipedQuestions, skippedQuestionsArray = []) => {
    setLetesSearchingIndex(participantIndex);
    if (participantIndex !== null) {
      const error = searchValidator(participantData[participantIndex], categories, participantIndex);
      if (error === null) {
        setSearchErrors([]);
        const selectedKeywords = Object.values(participantData[participantIndex]).filter((option) => {
          return option !== undefined && typeof option !== "string" && !(typeof option === "object" && Object.keys(option).length === 0) && option.keyword_id !== undefined && option.category_id !== undefined;
        });

        const payload = selectedKeywords.map((option) => ({
          keyword_id: option.keyword_id,
          category_id: option.category_id,
        }));

        const formData = new FormData();
        formData.append("search", JSON.stringify(payload));
        formData.append("limit", limitSkiipedQuestions ?? limit);
        skippedQuestionsArray.forEach((question, index) => {
          console.log({ question });
          formData.append(`skipped_questions[${index}]`, question);
        });

        dispatch(getQuestions(formData));
      } else {
        setSearchErrors(error);
      }
    }
  };

  const handleQuestionsChange = (questions, index) => {
    const updatedData = [...participantData];
    updatedData[index] = { ...updatedData[index], questions: questions };
    setParticipantData(updatedData);
  };

  const handleRefresh = () => {
    setParticipantData([]);
    dispatch(resetQuestions());
    setParticipants([]);
    setMeeting({
      companyName: "",
      name: "",
      date: new Date(),
    });
    setParticipantQuestions({});
  };
  const filterCategoryByName = (data, categoryName) => {
    const category = data?.find((category) => category.category_name.toLowerCase() === categoryName.toLowerCase());
    if (category) {
      return category.keywords.map((keyword) => ({
        value: keyword.keyword_value,
        label: keyword.keyword_value,
        keyword_id: keyword?.keyword_id,
        category_id: category?.category_id,
      }));
    }
    return [];
  };

  const handleRefreshSelectedQuestions = (index, limit, selectedQuestions) => {
    handleSearch(index, limit, selectedQuestions);
  };

  useEffect(() => {
    dispatch(getAllCategories());
  }, []);

  useEffect(() => {
    if (questions?.length > 0) {
      setParticipantQuestions({ ...particpantQuestions, [letesSearchingIndex]: questions });
    }
  }, [questions]);

  useEffect(() => {
    if (createMeeting?.status === "Success") {
      toast.success(createMeeting?.message || "Mreating created successfully");
      handleRefresh();
    } else if (createMeeting?.status === "Error") {
      toast.error(createMeeting?.message || "Somthing went wrong");
    }
    dispatch(resetCreateMeeting());
  }, [createMeeting]);

  return (
    <div className="main-container w-100">
      <Breadcrumb items={breadcrumbItems.createNewMeetings} />
      <MDBContainer className="d-flex justify-content-center align-items-center row me-auto ms-auto" ref={newMeetingRef}>
        <Loader isLoading={isLoading} />
        <MotionAnimate animation="fadeInUp" reset={false} distance={50} delay={0.4} speed={0.5}>
          <MDBRow className="w-100 col-12">
            <MDBCol className="d-flex justify-content-center w-100">
              <MDBCard className="w-100 p-3">
                <MDBCardBody>
                  <MDBCardTitle className="mb-3">
                    <Typewriter words={["Create a New Meeting"]} />
                  </MDBCardTitle>
                  <div className="row">
                    <div className="mb-4 col-4">
                      <label className="select-participant-label">Company Name:</label>
                      <input type="text" className="form-control py-2" placeholder="Enter Company Name" value={meeting.companyName} onChange={handleCompanyNameChange} />
                      {validationError?.company_name && <small className="text-danger">{validationError?.company_name}</small>}
                    </div>
                    <div className="mb-4 col-4">
                      <label className="select-participant-label">Meeting Name:</label>
                      <input type="text" className="form-control py-2" placeholder="Enter Meeting Name" value={meeting.name} onChange={handleMeetingNameChange} />
                      {validationError?.meeting_name && <small className="text-danger">{validationError?.meeting_name}</small>}
                    </div>
                    <div className="col-4">
                      <div className="select-participant-label">Select Date And Time:</div>
                      <DateTimePicker onChange={handleDateChange} value={meeting.date} disableClock={true} isClockOpen={false} />
                      {validationError?.meeting_date_time && <small className="text-danger">{validationError?.meeting_date_time}</small>}
                    </div>
                    <div className="mb-4 col-12">
                      <label className="select-participant-label">Add Participants (Maximum 3):</label>
                      <TagsInput isEditOnRemove={true} placeHolder="Add Participant" maxTags={3} value={participants} onChange={handleChange} />
                      {participants.some((tag) => tag.length > 50) && <small className="text-danger">Participant name should not be longer than 50 characters.</small>}
                      {validationError?.participants && <small className="text-danger">{validationError?.participants}</small>}
                    </div>
                  </div>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        </MotionAnimate>
        {participants?.length > 0 &&
          participants?.map((participant, i) => {
            const participantErrors = searchErrors?.filter((error) => error.index === i) || [];

            const getErrorMessage = (categoryId) => {
              const error = participantErrors.find((err) => err.category_id === categoryId);
              return error ? error.message : null;
            };
            return (
              <MotionAnimate key={i} animation="fadeInUp" reset={false} distance={50} delay={0.4} speed={0.5}>
                <MDBRow className="w-100 mt-5 col-12 mb-5">
                  <MDBCol>
                    <MDBCard className="w-100 p-3">
                      <MDBCardBody>
                        <MDBCardTitle className="mb-3">
                          Select dropdowns to receive category-specific questions for
                          <MDBBadge className="mx-2" color="success" light>
                            {participant}
                          </MDBBadge>
                        </MDBCardTitle>

                        <div className="row  g-3">
                          <div className="col-4">
                            <label className="select-participant-label">Job Title:</label>
                            <input type="text" className="form-control" style={{ paddingTop: "6px", paddingBottom: "6px" }} name={`jobTitle-${i}`} onChange={(e) => handleCategoryChange(i, "jobTitle", e.target.value)} placeholder="Enter Job Title" />
                            {getErrorMessage("jobTitle") && <small className="text-danger">{getErrorMessage("jobTitle")}</small>}
                          </div>
                          <div className="col-4">
                            <label className="select-participant-label">Select Sales Stage:</label>
                            <Select
                              className="basic-single"
                              components={animatedComponents}
                              classNamePrefix="select"
                              isDisabled={false}
                              isClearable={true}
                              isRtl={false}
                              isSearchable={true}
                              isLoading={isLoading}
                              name={`salesStage-${i}`}
                              options={filterCategoryByName(categories, "sales stage")}
                              // value={selectedCategories.salesStage}
                              onChange={(selectedOption) => handleCategoryChange(i, "salesStage", selectedOption)}
                            />
                            {getErrorMessage(1) && <small className="text-danger">{getErrorMessage(1)}</small>}
                          </div>
                          <div className="col-4 pl-2">
                            <label className="select-participant-label">Select Objective Sought:</label>
                            <Select
                              className="basic-single"
                              components={animatedComponents}
                              classNamePrefix="select"
                              isLoading={isLoading}
                              isDisabled={false}
                              isClearable={true}
                              isRtl={false}
                              isSearchable={true}
                              name={`objective-${i}`}
                              options={filterCategoryByName(categories, "objective")}
                              onChange={(selectedOption) => handleCategoryChange(i, "objective", selectedOption)}
                            />
                            {getErrorMessage(2) && <small className="text-danger">{getErrorMessage(2)}</small>}
                          </div>
                          <div className="col-4">
                            <label className="select-participant-label">Select Participant Title:</label>
                            <Select
                              className="basic-single"
                              classNamePrefix="select"
                              isLoading={isLoading}
                              isDisabled={false}
                              isClearable={true}
                              isRtl={false}
                              isSearchable={true}
                              name={`participantTitle-${i}`}
                              options={filterCategoryByName(categories, "role")}
                              // value={selectedCategories.participantTitle}
                              onChange={(selectedOption) => handleCategoryChange(i, "participantTitle", selectedOption)}
                            />
                            {getErrorMessage(3) && <small className="text-danger">{getErrorMessage(3)}</small>}
                          </div>
                          <div className="col-4 pl-2">
                            <label className="select-participant-label">Select Participant Role:</label>
                            <Select
                              className="basic-single"
                              classNamePrefix="select"
                              isLoading={isLoading}
                              isDisabled={false}
                              isClearable={true}
                              isRtl={false}
                              isSearchable={true}
                              name={`participantRole-${i}`}
                              options={filterCategoryByName(categories, "role in sales process")}
                              // value={selectedCategories.participantRole}
                              onChange={(selectedOption) => handleCategoryChange(i, "participantRole", selectedOption)}
                            />
                            {getErrorMessage(4) && <small className="text-danger">{getErrorMessage(4)}</small>}
                          </div>
                          <div className="col-4">
                            <label className="select-participant-label">Select Relationship Status:</label>
                            <Select
                              className="basic-single"
                              classNamePrefix="select"
                              isLoading={isLoading}
                              isDisabled={false}
                              isClearable={true}
                              isRtl={false}
                              isSearchable={true}
                              name={`relationshipStatus-${i}`}
                              options={filterCategoryByName(categories, "relationship status")}
                              // value={selectedCategories.relationshipStatus}
                              onChange={(selectedOption) => handleCategoryChange(i, "relationshipStatus", selectedOption)}
                            />
                            {getErrorMessage(5) && <small className="text-danger">{getErrorMessage(5)}</small>}
                          </div>
                          <div className="col-4 pl-2">
                            <label className="select-participant-label">Select Tone:</label>
                            <Select
                              className="basic-single"
                              classNamePrefix="select"
                              isLoading={isLoading}
                              isDisabled={false}
                              isClearable={true}
                              isRtl={false}
                              isSearchable={true}
                              name={`tone-${i}`}
                              options={filterCategoryByName(categories, "tone")}
                              onChange={(selectedOption) => handleCategoryChange(i, "tone", selectedOption)}
                            />
                            {getErrorMessage(6) && <small className="text-danger">{getErrorMessage(6)}</small>}
                          </div>

                          <div className="col-4">
                            <label className="select-participant-label">Select Rating:</label>
                            <Select
                              className="basic-single"
                              classNamePrefix="select"
                              isLoading={isLoading}
                              isDisabled={false}
                              isClearable={true}
                              isRtl={false}
                              isSearchable={true}
                              name={`rating-${i}`}
                              options={filterCategoryByName(categories, "user rating")}
                              onChange={(selectedOption) => handleCategoryChange(i, "rating", selectedOption)}
                            />
                            {getErrorMessage(7) && <small className="text-danger">{getErrorMessage(7)}</small>}
                          </div>
                        </div>

                        <MotionAnimate animation="fadeInUp" reset={false} distance={50} delay={0.4} speed={0.5}>
                          <MDBRow className="w-100 col-12 m-0 p-0">
                            <MDBCol className="d-flex mt-3 w-100 p-0">
                              <MDBBtn className="mt-3 mb-3 me-0 ms-0" disabled={!participantData[i]} color="dark" onClick={() => handleSearch(i)}>
                                Search
                              </MDBBtn>
                            </MDBCol>
                          </MDBRow>
                        </MotionAnimate>

                        {validationError[i] && <small className="text-danger">{validationError[i]}</small>}

                        {particpantQuestions[i]?.length > 0 ? (
                          <MotionAnimate key={i} animation="fadeInUp" reset={false} distance={50} delay={0.4} speed={0.5}>
                            <MDBCol className="d-flex w-100">
                              <DataTable data={particpantQuestions[i]} name={participant?.label} participants={participantData} index={i} getSelectedQuestions={handleQuestionsChange} handleRefreshSelectedQuestions={handleRefreshSelectedQuestions} status={createMeeting?.status === "Success"} />
                            </MDBCol>
                          </MotionAnimate>
                        ) : (
                          ""
                        )}
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                </MDBRow>
              </MotionAnimate>
            );
          })}
        <>
          <MDBRow className="w-100 col-12 m-0">
            <MDBCol className="d-flex w-100"></MDBCol>
          </MDBRow>
          <MotionAnimate animation="fadeInUp" reset={false} distance={50} delay={0.4} speed={0.5}>
            <MDBRow className="w-100 col-12 m-0">
              <MDBCol className="d-flex w-100">
                <MDBBtn className="mt-5 mb-5" color="dark" onClick={handleRefresh}>
                  Back
                </MDBBtn>
                <MDBBtn className="mt-5 mb-5 ms-2" color="dark" onClick={handleSubmit}>
                  Save
                </MDBBtn>
              </MDBCol>
            </MDBRow>
          </MotionAnimate>
        </>
      </MDBContainer>
      <ToastContainer />
    </div>
  );
};

export default CreateMeeting;
