import React from "react";
import MySidebarMenu from "../containers/Sidebar";
import { Outlet } from "react-router-dom";
// import "./UserLayout.css";

const UserLayout = () => {
  return (
    <div className="user-layout-container">
      <div className="user-layout-row">
        <div className="user-layout-sidebar ">
          <MySidebarMenu />
        </div>
        <div className="user-layout-content">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default UserLayout;
