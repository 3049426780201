import "./App.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import UserLayout from "./layout/UserLayout";
import { routes } from "./config/routes";
import CreateMeeting from "./pages/CreateMeeting";
import Error from "./pages/Error";
import "./styles/common.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
function App() {
  const userType = useSelector((state) => state.auth.userType);
  console.log({ userType });
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigate to={routes.auth.login.path} />} />
          <Route path={routes.auth.login.path} element={<routes.auth.login.component />} />
          <Route path={routes.auth.signUp.path} element={<routes.auth.signUp.component />} />
          <Route path={routes.auth.forgotPassword.path} element={<routes.auth.forgotPassword.component />} />
          {userType === "User" && (
            <Route path="/user/*" element={<UserLayout />}>
              <Route path={routes.panel.adminPanel.Dashboard.path} element={<routes.panel.adminPanel.Dashboard.component />} />

              <Route path={routes.panel.userPanel.createMeeting.path} element={<CreateMeeting />} />
              <Route path={routes.panel.userPanel.savedMeetings.path} element={<routes.panel.userPanel.savedMeetings.component />} />
              <Route path={routes.panel.userPanel.favoriteQuestons.path} element={<routes.panel.userPanel.favoriteQuestons.component />} />
              <Route path="*" element={<Navigate to={routes.error} />} />
            </Route>
          )}
          {userType === "Admin" && (
            <Route path="/admin/*" element={<UserLayout />}>
              <Route path={routes.panel.adminPanel.Dashboard.path} element={<routes.panel.adminPanel.Dashboard.component />} />
              <Route path={routes.panel.adminPanel.createMeeting.path} element={<CreateMeeting />} />
              <Route path={routes.panel.adminPanel.savedMeetings.path} element={<routes.panel.adminPanel.savedMeetings.component />} />
              <Route path={routes.panel.adminPanel.favoriteQuestons.path} element={<routes.panel.adminPanel.favoriteQuestons.component />} />
              <Route path={routes.panel.adminPanel.addFiles.path} element={<routes.panel.adminPanel.addFiles.component />} />
              <Route path="*" element={<Navigate to={routes.error} />} />
            </Route>
          )}
          <Route path="*" element={<Navigate to={routes.error} />} />
          <Route path={routes.error} element={<Error />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
